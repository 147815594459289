// Example preview.js file

import * as React from "react";
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews";
import Layout from "../components/shared/layout";

const PreviewPage = () => {
  return (
    <Layout>
      <div>
        <h1>Loading preview…</h1>
      </div>
    </Layout>
  );
};

export default withPrismicPreviewResolver(
  PreviewPage
  //   , [
  //   {
  //     repositoryName: "pdf-websites",
  //     linkResolver,
  //     fetchLinks: ["pdfwriter_post.slug_prefix"],
  //   },
  // ]
);
